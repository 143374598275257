import React from "react";
import S from "styled-components";
import theme from "../Theme";

const Wrapper = S.div`
    padding-right: ${(p) => p.pRight};
    padding-bottom: ${(p) => p.pBottom};
    padding-left: ${(p) => p.pLeft};
    padding-top: ${(p) => p.pTop};
    margin-left: ${(m) => m.mLeft};
    margin-right: ${(m) => m.mRight};
    min-height: 100px;
    background-color: ${(p) => p.backgroundColor};
    text-align: ${(p) => p.textAlign};
	id: ${(p) => p.id};
	overflow-x: hidden;
	width: 100%;
    background-image: ${(p) => p.backgroundImage};
    &.how-to-use {
        background-position: right;
        background-repeat: no-repeat;
    }
`;

const Container = S.div`
    height:inherit;
    max-width: ${theme.maxWidth};
    margin: 0 auto;
    
    ${(p) => (p.noContainerPadding ? `` : `padding: 0 1rem;`)}
`;

const Section = ({
    pTop,
    pBottom,
    pLeft,
    pRight,
    mLeft,
    mRight,
    children,
    backgroundColor,
    backgroundImage,
    textAlign,
    noContainerPadding,
    id,
    className,
}) => {
    return (
        <Wrapper
            className={className}
            pTop={pTop}
            pBottom={pBottom}
            pLeft={pLeft}
            pRight={pRight}
            mLeft={mLeft}
            mRight={mRight}
            backgroundColor={backgroundColor}
            textAlign={textAlign}
            id={id}
            backgroundImage={backgroundImage}>
            <Container noContainerPadding={noContainerPadding}>
                {children}
            </Container>
        </Wrapper>
    );
};

Section.defaultProps = {
    pTop: "",
    pBottom: "",
    pLeft: "",
    pRight: "",
};

export default Section;
