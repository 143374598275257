import React, { useState, useEffect } from "react";
import { useWindowSize } from "../util";
import theme from "../components/Theme";
import Section from "../components/UiWrappers/Section";
import Row from "../components/UiWrappers/Row";
import Column from "../components/UiWrappers/Column";
import { Button } from "../components/Button";
import ProductButton, { ProductCard } from "../components/Product";
import Feature from "../components/UI/Feature";
import HowItWorks from "../components/UI/HowItWorks";
import WhyPrescriptionOnline from "../components/UI/WhyPrescriptionOnline";
import S from "styled-components";
import Text from "../components/UiWrappers/Text";
import Head from "next/head";
import { getProductsByCategory } from "../components/Product/mocks/mock";
import { useSelector } from "react-redux";

const Wrapper = S.div`
	max-width: 500px;
`;

const Index = ({ title, description }) => {
    const { width } = useWindowSize();
    const isMobile = () => width <= theme.mobileBreakpoint;

    const userLocation = useSelector((state) => state.appReducer.userLocation);

    const edProducts = getProductsByCategory("ed");
    const bcProducts = getProductsByCategory("bc");

    const [healcareProfessionalTitle, setHealcareProfessionalTitle] = useState(
        "Licensed healthcare <br/> professionals"
    );

    useEffect(() => {
        if (userLocation) {
            if (userLocation === "US") {
                setHealcareProfessionalTitle(
                    "US-licensed healthcare <br/> professionals"
                );
            } else if (userLocation === "CA") {
                setHealcareProfessionalTitle(
                    "Canadian-licensed healthcare <br/> professionals"
                );
            }
        }
    }, [userLocation]);

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="description" content={`${description}`} />
            </Head>
            {/* Refactor this into its own Hero component*/}
            <Section pTop={isMobile() ? "0" : "100px"} pBottom="100px">
                <Row>
                    <Column sm={24} md={24} lg={11} textAlign="left">
                        <Text type={isMobile() ? "h3" : "h1"}>
                            No more clinic <br />
                            waitrooms for your prescription
                        </Text>

                        <Text type="body6">
                            Prescribe Online is the answer for anyone who would
                            prefer the convenience of having their prescriptions
                            written online.
                        </Text>
                        <Button
                            style={isMobile() ? { width: "100%" } : {}}
                            title="Start your visit"
                            href="#erectile"
                            type="primary"
                        />
                    </Column>
                    <Column sm={24} md={24} lg={1} textAlign="left" />
                    <Column sm={24} md={11} lg={6} paddingTop="40px">
                        <ProductCard
                            ml={"auto"}
                            title="Erectile Dysfunction"
                            type="secondary"
                            image="/images/ed.png"
                            href="/category/erectile"
                        />
                    </Column>
                    <Column sm={0} md={2} lg={0} textAlign="left" />
                    <Column sm={24} md={11} lg={6} paddingTop="40px">
                        <ProductCard
                            ml={"auto"}
                            title="Birth Control"
                            type="primary"
                            image="/images/bc.png"
                            href="/category/birth-control"
                        />
                    </Column>
                </Row>
            </Section>
            <Section
                pTop={"2rem"}
                pBottom={"1rem"}
                backgroundColor={theme.colors.primary50}>
                <Row>
                    <Feature
                        img={"box1.png"}
                        title={
                            "FDA-approved ED <br/> and Birth Control <br/> pills"
                        }
                    />
                    <Feature
                        img={"box2.png"}
                        title={healcareProfessionalTitle}
                    />
                    <Feature
                        img={"box3.png"}
                        title={`Free <br/> prescription`}
                    />
                    <Feature
                        img={"box4.png"}
                        title={`Discreet Delivery through <br/> one of our partner <br/> pharmacies `}
                    />
                </Row>
            </Section>
            <Section>
                <HowItWorks />
            </Section>
            <Section
                backgroundColor={theme.colors.primary80}
                pBottom={isMobile() ? "60px" : 0}
                pTop="60px"
                id="erectile">
                <Row>
                    <Column
                        sm={24}
                        md={24}
                        lg={10}
                        textAlign="left"
                        paddingTop={isMobile() ? "2rem" : "0"}
                        mobileOrder={1}>
                        <Text type="h4">What we carry</Text>
                        {edProducts.map((product) => (
                            <ProductButton
                                product={product}
                                title={product.name}
                                type="secondary"
                                mt="10px"
                                mb="10px"
                                fullWidth
                                rightIcon="/images/productitemarroworange.svg"
                            />
                        ))}
                    </Column>
                    <Column sm={24} md={24} lg={2}></Column>
                    <Column sm={24} md={24} lg={12} textAlign="left">
                        <Text type={isMobile() ? "h3" : "h2"}>
                            Erectile Dysfunction
                        </Text>
                        <Wrapper>
                            <Text type="body7">
                                A healthy sex life with their partner is
                                important for a man, and erectile dysfunction
                                can get in the way of that. Get your ED
                                medication prescription online here.
                            </Text>
                        </Wrapper>

                        {!isMobile() && (
                            <img
                                src="/images/couple.png"
                                style={{ maxWidth: 300 }}
                            />
                        )}
                    </Column>
                </Row>
            </Section>
            <Section
                backgroundColor={theme.colors.secondary50}
                pBottom={isMobile() ? "60px" : 0}
                pTop="60px"
                id="birthcontrol">
                <Row>
                    <Column sm={24} md={24} lg={12} textAlign="left">
                        <Text type={isMobile() ? "h3" : "h2"}>
                            Birth control
                        </Text>
                        <Wrapper>
                            <Text type="body7">
                                Birth control pills are an important part of
                                family planning for many women, and you can get
                                your birth control prescription online here.
                            </Text>
                        </Wrapper>

                        {!isMobile() && (
                            <img
                                src="/images/happy-female.png"
                                style={{ maxWidth: 300 }}
                            />
                        )}
                    </Column>
                    <Column
                        sm={24}
                        md={24}
                        lg={10}
                        textAlign="left"
                        paddingTop={isMobile() ? "2rem" : "0"}>
                        <Text type="h4">What we carry</Text>
                        {bcProducts.map((product) => (
                            <ProductButton
                                product={product}
                                title={product.name}
                                type="primary"
                                mt="10px"
                                mb="10px"
                                fullWidth
                                rightIcon="/images/productitemarroworange.svg"
                            />
                        ))}
                    </Column>
                    <Column sm={24} md={24} lg={4} />
                </Row>
            </Section>
            <Section pBottom="60px">
                <WhyPrescriptionOnline />
                <div
                    style={{
                        textAlign: "center",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}>
                    <Button
                        title="Start your visit"
                        type="primary"
                        href="#erectile"
                    />
                </div>
            </Section>
        </>
    );
};

export async function getStaticProps() {
    //TO BE SET LATER
    const title = "Get Prescription Online – Licensed Healthcare Professionals";
    const description =
        "Ready to skip the busy clinic waiting room? Prescribe Online will connect you to licensed healthcare professionals virtually to get your prescriptions online.";
    return { props: { title, description } };
}

export default Index;
