import React, { Fragment } from "react";
import S from "styled-components";
import theme from "../Theme";
import Text from "../UiWrappers/Text";
import { Row } from "antd";
import Column from "../UiWrappers/Column";
import "antd/dist/antd.css";
import Card from "../UiWrappers/Card";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "../../util";
import Link from "next/link";

const Wrapper = S.div`
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
`;
const DescriptionWrapper = S.div`
    margin: auto;
    max-width: 900px;
`;

const Icon = S.img`
    width: 40px;
    margin-bottom: 20px;
`;
const CardLink = S.a`
    color:${theme.colors.orange};
    display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledIcon = S(FontAwesomeIcon)`
	margin-left: 12px;
    width:15px;
`;

const MobileText = `It’s an easy a few steps process that could save you the line up time at your doctor’s`;
const WebText = `It's an easy process of just few steps that will mean you don't need to see a doctor to get your prescription. If you're approved based on your questionnaire your prescription will be written and then sent to one of our partner pharmacies.`;

const MobileIcon = S.div`
	@media ${theme.breakpoints.xs} {
		height: 40px;
		width:40px;
		position: absolute;
		top:-1rem;
		left:-1rem;
		background:#E7E8FB80;
		border-radius:10px;
		box-shadow: 0px 4px 14px 10px rgba(231, 232, 251, 0.2);
	}
	@media ${theme.breakpoints.sm} {
	
	}
	@media ${theme.breakpoints.md} {
	
	}
    @media screen and (min-width: 993px) {
        display: none;
    }
	@media ${theme.breakpoints.lg} {
		display: none;
	}
`;

const HowItWorksColumn = ({ step, children, color }) => {
    const { width } = useWindowSize();
    const isMobile = () => width <= theme.mobileBreakpoint;
    return (
        <Fragment>
            <Column sm={24} md={8} lg={4}>
                {!isMobile() && (
                    <Text type="h1" align="center" color={color}>
                        {step}.
                    </Text>
                )}
                <Card>
                    <MobileIcon>
                        <Text mb={0} type="h3" align="center" color={color}>
                            {step}.
                        </Text>
                    </MobileIcon>
                    {children}
                </Card>
            </Column>
            <Column sm={0} md={0} lg={1} />
        </Fragment>
    );
};

const HowItWorks = () => {
    const { width } = useWindowSize();
    const isMobile = () => width <= theme.mobileBreakpoint;
    return (
        <>
            <Wrapper>
                <Text
                    mb={isMobile() ? "1rem" : ""}
                    type={isMobile() ? "h4" : "h1"}
                    align="center">
                    How it works
                </Text>
                <DescriptionWrapper>
                    <Text type="body6" align="center" mb="2rem">
                        {isMobile() ? MobileText : WebText}
                    </Text>
                </DescriptionWrapper>
                <Row justify="center">
                    <HowItWorksColumn color={theme.colors.primary50} step={1}>
                        <Icon src="/images/pills.svg" />
                        <Text type="body6" align="center">
                            Select a product
                        </Text>
                        <Link href={"/what-we-carry"}>
                            <CardLink href={"/what-we-carry"}>
                                Browse products
                                <StyledIcon icon={faArrowRight} />
                            </CardLink>
                        </Link>
                    </HowItWorksColumn>
                    <HowItWorksColumn color={theme.colors.primary100} step={2}>
                        <Icon src="/images/frame.svg" />
                        <Text type="body6" align="center">
                            Answer health questionnaire
                        </Text>
                    </HowItWorksColumn>
                    <HowItWorksColumn color={theme.colors.primary300} step={3}>
                        <Icon src="/images/doctor.svg" />
                        <Text type="body6" align="center">
                            Health professional reviews and approves the
                            questionnaire
                        </Text>
                    </HowItWorksColumn>
                    <HowItWorksColumn color={theme.colors.primary500} step={4}>
                        <Icon src="/images/health.svg" />
                        <Text type="body6" align="center">
                            Healthcare professional prescribes RX
                        </Text>
                    </HowItWorksColumn>
                    <HowItWorksColumn color={theme.colors.primary800} step={5}>
                        <Icon src="/images/delivery.svg" />
                        <Text type="body6" align="center">
                            Package is out for delivery via one of our partner
                            pharmacies
                        </Text>
                    </HowItWorksColumn>
                </Row>
            </Wrapper>
        </>
    );
};

export default HowItWorks;
