import React from "react";
import S from "styled-components";
import theme from "../Theme";

const Wrapper = S.div`
    width: 90%;
    box-shadow: 0px 4px 14px 10px rgba(231, 232, 251, 0.2);
    border-radius: 10px;
    width: 200px;
    height: auto;
    min-height: 200px;
    padding: 20px;
    position:relative;
    
    @media ${theme.breakpoints.xs} {
		margin: 0  auto;
		margin-bottom: 2rem;
	}
	@media ${theme.breakpoints.sm} {
	
	}
	@media ${theme.breakpoints.md} {
	
	}
	@media ${theme.breakpoints.lg} {
		margin: 0;
	}
`;

const Card = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

export default Card;
