import React, { Fragment } from "react";
import Icon from "../UiWrappers/Icon";
import Text from "../UiWrappers/Text";
import Column from "../UiWrappers/Column";

interface Props {
    img: string;
    title: string;
}

const Feature = ({ img, title }: Props) => {
    return (
        <Column sm={12} md={12} lg={6}>
            <Icon src={`/images/${img}`} marginBottom="20px" width="80px" />
            <Text type="body6" align="center">
                {title.split(`<br/>`).reduce(
                    (arr, line, index) =>
                        arr.concat(
                            <Fragment key={index}>
                                {line}
                                <br />
                            </Fragment>
                        ),
                    []
                )}
            </Text>
        </Column>
    );
};

export default Feature;
