import React from "react";
import S from "styled-components";

const CustomIcon = S.img`
    
`;

interface Props {
    width: string;
    marginBottom: string;
    src: string;
}

const Icon = ({ width, marginBottom, src }: Props) => {
    return (
        <>
            <CustomIcon
                src={src}
                width={width}
                style={{ marginBottom: marginBottom }}
            />
        </>
    );
};

Icon.defaultProps = {
    with: "50px",
};

export default Icon;
