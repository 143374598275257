import React from "react";
import S from "styled-components";
import Text from "../UiWrappers/Text";
import { Row } from "antd";
import "antd/dist/antd.css";
import Column from "../UiWrappers/Column";
import { useWindowSize } from "../../util";
import theme from "../Theme";

const Wrapper = S.div`
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
`;
const DescriptionWrapper = S.div`
    margin: auto;
    max-width: 740px;
`;

const Icon = S.img`
    width: 80px;
    margin-bottom: 20px;
`;

const WhyPrescriptionOnline = () => {
    const { width } = useWindowSize();
    const isMobile = () => width <= theme.mobileBreakpoint;
    return (
        <>
            <Wrapper>
                <Text type={isMobile() ? "h3" : "h1"} align="center">
                    Why Prescribe Online
                </Text>
                <DescriptionWrapper>
                    <Text type="body6" align="center">
                        Prescribe Online works with licensed physicians who are
                        able to write prescriptions for Rx medications based on
                        your answers to our health questionnaire. We're then
                        able to have one of our partner pharmacies fill your
                        prescription and have it delivered to you. You're going
                        to like the convenience of getting prescriptions online.
                    </Text>
                </DescriptionWrapper>
                <Row>
                    <Column sm={24} md={24} lg={4} padding="20px">
                        <Icon src="/images/pill1.png" />
                        <Text type="body6" align="center">
                            FDA-approved ED and Birth Control pills
                        </Text>
                    </Column>
                    <Column sm={24} md={24} lg={1} />
                    <Column sm={24} md={24} lg={4} padding="20px">
                        <Icon src="/images/pill2.png" />
                        <Text type="body6" align="center">
                            Licensed healthcare professionals
                        </Text>
                    </Column>
                    <Column sm={24} md={24} lg={1} />
                    <Column sm={24} md={24} lg={4} padding="20px">
                        <Icon src="/images/pill3.png" />
                        <Text type="body6" align="center">
                            No cost to you!
                        </Text>
                    </Column>
                    <Column sm={24} md={24} lg={1} />
                    <Column sm={24} md={24} lg={4} padding="20px">
                        <Icon src="/images/pill4.png" />
                        <Text type="body6" align="center">
                            No waiting weeks to see a doctor
                        </Text>
                    </Column>
                    <Column sm={24} md={24} lg={1} />
                    <Column sm={24} md={24} lg={4} padding="20px">
                        <Icon src="/images/pill5.png" />
                        <Text type="body6" align="center">
                            100% online, discreet delivery
                        </Text>
                    </Column>
                </Row>
            </Wrapper>
        </>
    );
};
export default WhyPrescriptionOnline;
